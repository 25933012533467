import apiClient from '@/services/axios'

const END_POINT = '/ortb/bannerzone'

export async function getBannerZones(id = null) {
  const payload = id != null ? { params: { Id: parseInt(id) } } : null

  return apiClient.get(END_POINT + '/get', payload).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

/**
 * Get publisher feed data
 *
 * @param {int} id - User ID
 * @returns {Promise} - Request promise
 */
export async function getBannerzones(id = null) {
  const payload = id != null ? { Id: id } : null

  return apiClient.get(END_POINT + '/get', payload).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

export async function getBannerzonesForPublisher(publisherId) {
  return apiClient.get(END_POINT + '/get', {
    params: { PublisherId: publisherId },
  }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}
export async function getBannerzonesShort() {
  return apiClient.get(END_POINT + '/get/short', {
  }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}
export async function getBannerZonesForUserAndPublisher(accManagerId, publisherId) {
  let paramAccManagerId = accManagerId
  let paramPublisherId = publisherId
  if (!paramAccManagerId) {
    paramAccManagerId = '0'
  } else if (!paramPublisherId) {
    paramPublisherId = '0'
  }
  return apiClient.get(END_POINT + '/get', {
    params: { AccManagerId: accManagerId, PublisherId: publisherId },
  }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

/**
 * Create new publisher feed
 *
 * @param {Object} payload - Data
 * @returns {Promise} - Request promise
 */
export async function createBannerzone(payload) {
  if (typeof payload !== 'object' || payload == null) {
    return false
  }
  // Parameter list
  const parameters = {
    Id: {
      type: 'int',
      required: false,
      map: 'bannerzoneId',
    },
    TeamId: {
      type: 'int',
      required: false,
      map: 'teamId',
    },
    PublisherId: {
      type: 'int',
      required: true,
      map: 'publisher',
    },
    PublisherName: {
      type: 'string',
      required: false,
      map: 'publisherName',
    },
    Name: {
      type: 'string',
      required: true,
    },
    Description: {
      map: 'description',
      type: 'string',
      required: false,
    },
    Width: {
      map: 'width',
      type: 'int',
      required: false,
    },
    Height: {
      map: 'height',
      type: 'int',
      required: false,
    },
    IabCategories: {
      map: 'iabCategories',
      type: 'arr',
      required: false,
    },
    BlockedAdIabCategories: {
      map: 'blockedIAB',
      type: 'arr',
      required: false,
    },
    BlockedAdAttributes: {
      map: 'blockedAdAttrs',
      type: 'arr',
      required: false,
    },
    AdAppsBlacklist: {
      map: 'AdAppsBlacklist',
      type: 'string',
      required: false,
    },
    Keywords: {
      map: 'keywords',
      type: 'string',
      required: false,
    },
    SiteDomain: {
      map: 'siteDomain',
      type: 'string',
      required: false,
    },
    AppName: {
      map: 'appName',
      type: 'string',
      required: false,
    },
    AppBundle: {
      map: 'appBundle',
      type: 'string',
      required: false,
    },
    AppStoreUrl: {
      map: 'appStoreUrl',
      type: 'string',
      required: false,
    },
    Status: {
      map: 'Status',
      type: 'int',
      required: false,
    },
    StatusString: {
      map: 'statusString',
      type: 'string',
      required: false,
    },
    Ads: {
      map: 'ads',
      type: 'arr',
      required: false,
    },
    Publisher: {
      map: 'publisher',
      type: 'obj',
      required: false,
    },
    AdDomainsBrandsBlacklist: {
      map: 'AdDomainsBrandsBlacklist',
      type: 'string',
      required: false,
    },
    Secure: {
      map: 'secure',
      type: 'int',
      required: false,
    },
    Bidfloor: {
      map: 'bidfloor',
      type: 'float',
      required: false,
    },
    PublisherRevShare: {
      map: 'publisherRevShare',
      type: 'float',
      required: false,
    },
    AuctionType: {
      map: 'auctionType',
      type: 'int',
      required: false,
    },
    Tmax: {
      type: 'int',
      required: false,
      map: 'tmax',
    },
    AdPosition: {
      type: 'int',
      required: false,
    },
    RequestsDailyLimit: {
      type: 'int',
      required: false,
      map: 'requestsDailyLimit',
    },
    BidsDailyLimit: {
      type: 'int',
      required: false,
      map: 'bidsDailyLimit',
    },
    ImpressionsDailyLimit: {
      type: 'int',
      required: false,
      map: 'impressionsDailyLimit',
    },
    SpentDailyLimit: {
      type: 'float',
      required: false,
      map: 'spentDailyLimit',
    },
  }
  // Prepare and validate data
  const preparedData = apiClient.prepare(payload, parameters)
  if (typeof preparedData.type !== 'undefined' && preparedData.type === 'error' && typeof preparedData.message !== 'undefined') {
    return preparedData
  }

  // Format Subid list
  if (typeof preparedData.SubidList !== 'undefined' && preparedData.SubidList.length > 0) {
    preparedData.SubidList = preparedData.SubidList.join('\n')
  }
  // Format AdDomains Brands  Blacklist
  // if (typeof preparedData.AdDomainsBrandsBlacklist !== 'undefined' && preparedData.AdDomainsBrandsBlacklist.length > 0) {
  //   preparedData.AdDomainsBrandsBlacklist = preparedData.AdDomainsBrandsBlacklist.join('\n')
  // }

  return apiClient.post(END_POINT + '/add', { Bannerzone: preparedData }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

/**
 * Update publisher feed data
 *
 * @param {Object} payload - Update parameters
 * @returns {Promise} - Request promise
 */
export async function updateBannerzone(payload) {
  if (typeof payload !== 'object' || payload == null) {
    return false
  }
  const parameters = {
    Id: {
      type: 'int',
      required: false,
      map: 'bannerzoneId',
    },
    TeamId: {
      type: 'int',
      required: false,
      map: 'teamId',
    },
    PublisherId: {
      type: 'int',
      required: false,
      map: 'publisher',
    },
    PublisherName: {
      type: 'string',
      required: false,
      map: 'publisherName',
    },
    Name: {
      type: 'string',
      required: true,
    },
    Description: {
      map: 'description',
      type: 'string',
      required: false,
    },
    Width: {
      map: 'width',
      type: 'int',
      required: false,
    },
    Height: {
      map: 'height',
      type: 'int',
      required: false,
    },
    IabCategories: {
      map: 'iabCategories',
      type: 'arr',
      required: false,
    },
    BlockedAdIabCategories: {
      map: 'blockedIAB',
      type: 'arr',
      required: false,
    },
    BlockedAdAttributes: {
      map: 'blockedAdAttrs',
      type: 'arr',
      required: false,
    },
    AdAppsBlacklist: {
      map: 'AdAppsBlacklist',
      type: 'string',
      required: false,
    },
    Keywords: {
      map: 'keywords',
      type: 'string',
      required: false,
    },
    SiteDomain: {
      map: 'siteDomain',
      type: 'string',
      required: false,
    },
    AppName: {
      map: 'appName',
      type: 'string',
      required: false,
    },
    AppBundle: {
      map: 'appBundle',
      type: 'string',
      required: false,
    },
    AppStoreUrl: {
      map: 'appStoreUrl',
      type: 'string',
      required: false,
    },
    Status: {
      map: 'Status',
      type: 'int',
      required: false,
    },
    StatusString: {
      map: 'statusString',
      type: 'string',
      required: false,
    },
    Ads: {
      map: 'ads',
      type: 'arr',
      required: false,
    },
    Publisher: {
      map: 'publisher',
      type: 'obj',
      required: false,
    },
    AdDomainsBrandsBlacklist: {
      map: 'AdDomainsBrandsBlacklist',
      type: 'string',
      required: false,
    },
    Secure: {
      map: 'secure',
      type: 'int',
      required: false,
    },
    Campaigns: {
      map: 'campaigns',
      type: 'arr',
      required: false,
    },
    Remotertbzones: {
      map: 'remotertbzones',
      type: 'arr',
      required: false,
    },
    Bidfloor: {
      map: 'bidfloor',
      type: 'float',
      required: false,
    },
    PublisherRevShare: {
      map: 'publisherRevShare',
      type: 'float',
      required: false,
    },
    AuctionType: {
      map: 'auctionType',
      type: 'int',
      required: false,
    },
    Tmax: {
      type: 'int',
      required: false,
      map: 'tmax',
    },
    AdPosition: {
      type: 'int',
      required: false,
    },
    RequestsDailyLimit: {
      type: 'int',
      required: false,
      map: 'requestsDailyLimit',
    },
    BidsDailyLimit: {
      type: 'int',
      required: false,
      map: 'bidsDailyLimit',
    },
    ImpressionsDailyLimit: {
      type: 'int',
      required: false,
      map: 'impressionsDailyLimit',
    },
    SpentDailyLimit: {
      type: 'float',
      required: false,
      map: 'spentDailyLimit',
    },
  }
  // Prepare and validate data
  const preparedData = apiClient.prepare(payload, parameters)
  if (typeof preparedData.type !== 'undefined' && preparedData.type === 'error' && typeof preparedData.message !== 'undefined') {
    return preparedData
  }

  // Format Subid list
  if (typeof preparedData.SubidList !== 'undefined' && preparedData.SubidList.length > 0) {
    preparedData.SubidList = preparedData.SubidList.join('\n')
  }
  // Format AdDomains Brands  Blacklist
  // if (typeof preparedData.AdDomainsBrandsBlacklist !== 'undefined' && preparedData.AdDomainsBrandsBlacklist.length > 0) {
  //   preparedData.AdDomainsBrandsBlacklist = preparedData.AdDomainsBrandsBlacklist.join('\n')
  // }
  return apiClient.post(END_POINT + '/update', { Bannerzone: preparedData }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

/**
 * Change publisher feed status
 *
 * @param {Object} payload - Update parameters
 * @returns {Promise} - Request promise
 */
export async function changeBannerzoneStatus(payload) {
  if (typeof payload !== 'object' || payload == null) {
    return false
  }
  // Parameter list
  const parameters = {
    Id: {
      type: 'int',
      required: true,
    },
    // Status: { type: 'int', required: true },
  }
  // Prepare and validate data
  const preparedData = apiClient.prepare(payload, parameters)
  if (typeof preparedData.type !== 'undefined' && preparedData.type === 'error' && typeof preparedData.message !== 'undefined') {
    return preparedData
  }

  // Get status endpoint
  let requestType = '/activate'
  if (payload.status === 1) {
    requestType = '/deactivate'
  } else if (payload.status === 2) {
    requestType = '/delete'
  }

  return apiClient.post(END_POINT + requestType, { Bannerzone: preparedData }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

/**
 * Duplicate existing Feed
 *
 * @param {int} id - Feed id
 * @returns {Promise} - Request promise
 */
export async function cloneBannerzone(id) {
  // todo add type check
  return apiClient.post(END_POINT + '/duplicate', {
    Bannerzone: { Id: id },
  }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}
