import apiClient from '@/services/axios'

const END_POINT = '/ortb/bannerad'

/**
 * Get banner ads
 *
 * @param {int} id - User ID
 * @returns {Promise} - Request promise
 */
export async function getBannerAds(id = null) {
  const payload = id != null ? { params: { Id: parseInt(id) } } : null

  return apiClient.get(END_POINT + '/get', payload).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

export async function getBannerAdsForCampaign(campaignId) {
  return apiClient.get(END_POINT + '/get', {
    params: { CampaignId: campaignId },
  }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}
export async function getBannerAdsShort() {
  return apiClient.get(END_POINT + '/get/short', {
  }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}
export async function getBannerAdsForUserAndCampaign(accManagerId, campaignId) {
  let paramAccManagerId = accManagerId
  let paramCampaignId = campaignId
  if (!paramAccManagerId) {
    paramAccManagerId = '0'
  } else if (!paramCampaignId) {
    paramCampaignId = '0'
  }
  return apiClient.get(END_POINT + '/get', {
    params: { AccManagerId: accManagerId, CampaignId: campaignId },
  }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

/**
 * Create new Ad
 *
 * @param {Object} payload - Data
 * @returns {Promise} - Request promise
 */
export async function createBannerAd(payload) {
  if (typeof payload !== 'object' || payload == null) {
    return false
  }
  // Parameter list
  const parameters = {
    CampaignId: {
      type: 'int',
      required: true,
      map: 'campaign',
    },
    Name: {
      type: 'string',
      required: true,
    },
    DefaultCPM: {
      type: 'float',
      required: false,
      map: 'defaultCPM',
    },
    ClicksDailyLimit: {
      type: 'int',
      required: false,
      map: 'clicksDailyLimit',
    },
    ImpressionsDailyLimit: {
      type: 'int',
      required: false,
      map: 'impressionsDailyLimit',
    },
    SpentDailyLimit: {
      type: 'int',
      required: false,
      map: 'spentDailyLimit',
    },
    Description: {
      map: 'description',
      type: 'string',
      required: false,
    },
    AdDomainBrand: {
      type: 'string',
      required: false,
      map: 'adDomainBrand',
    },
    AdAppBundle: {
      type: 'string',
      required: false,
      map: 'adAppBundle',
    },
    DestinationUrl: {
      type: 'string',
      required: true,
    },
    AdPosition: {
      type: 'int',
      required: false,
    },
    BidsDailyLimit: {
      type: 'int',
      map: 'bidsDailyLimit',
      required: false,
    },
    AdIabCategories: {
      type: 'arr',
      required: false,
    },
    AdAttributes: {
      type: 'arr',
      required: false,
    },
    Creatives: {
      type: 'intArray',
      required: true,
    },
  }
  // Prepare and validate data
  const preparedData = apiClient.prepare(payload, parameters)
  if (typeof preparedData.type !== 'undefined' && preparedData.type === 'error' && typeof preparedData.message !== 'undefined') {
    return preparedData
  }

  return apiClient.post(END_POINT + '/add', { Bannerad: preparedData }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

/**
 * Update Ad
 *
 * @param {Object} payload - Update parameters
 * @returns {Promise} - Request promise
 */
export async function updateBannerAd(payload) {
  if (typeof payload !== 'object' || payload == null) {
    return false
  }
  // Parameter list
  const parameters = {
    Id: {
      type: 'int',
      required: true,
    },
    Name: {
      map: 'name',
      type: 'string',
      required: true,
    },
    DefaultCPM: {
      map: 'defaultCPM',
      type: 'float',
      required: false,
    },
    ClicksDailyLimit: {
      type: 'int',
      required: false,
    },
    ImpressionsDailyLimit: {
      type: 'int',
      required: false,
    },
    SpentDailyLimit: {
      type: 'int',
      required: false,
    },
    Description: {
      map: 'description',
      type: 'string',
      required: false,
    },
    AdDomainBrand: {
      type: 'string',
      required: false,
    },
    AdAppBundle: {
      type: 'string',
      required: false,
    },
    DestinationUrl: {
      type: 'string',
      required: false,
    },
    AdPosition: {
      type: 'int',
      required: false,
    },
    BidsDailyLimit: {
      type: 'int',
      map: 'bidsDailyLimit',
      required: false,
    },
    AdIabCategories: {
      type: 'arr',
      required: false,
    },
    AdAttributes: {
      type: 'arr',
      required: false,
    },
    Countries: {
      type: 'objectArray',
      required: false,
    },
    Os: {
      type: 'objectArray',
      required: false,
    },
    Browsers: {
      type: 'objectArray',
      required: false,
    },
    Bannerzones: {
      type: 'intArray',
      required: false,
    },
    Rtbzones: {
      type: 'intArray',
      required: false,
    },
    Creatives: {
      type: 'intArray',
      required: true,
    },
  }
  // Prepare and validate data
  const preparedData = apiClient.prepare(payload, parameters)
  if (typeof preparedData.type !== 'undefined' && preparedData.type === 'error' && typeof preparedData.message !== 'undefined') {
    return preparedData
  }
  // console.log(preparedData)
  // return false
  return apiClient.post(END_POINT + '/update', { Bannerad: preparedData }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

/**
 * Change Ad
 *
 * @param {Object} payload - Update parameters
 * @returns {Promise} - Request promise
 */
export async function changeBannerAdStatus(payload) {
  if (typeof payload !== 'object' || payload == null) {
    return false
  }
  // Parameter list
  const parameters = {
    Id: {
      type: 'int',
      required: true,
    },
    // Status: { type: 'int', required: true },
  }
  // Prepare and validate data
  const preparedData = apiClient.prepare(payload, parameters)
  if (typeof preparedData.type !== 'undefined' && preparedData.type === 'error' && typeof preparedData.message !== 'undefined') {
    return preparedData
  }

  // Get status endpoint
  let requestType = '/activate'
  if (payload.status === 1) {
    requestType = '/deactivate'
  } else if (payload.status === 2) {
    requestType = '/delete'
  }

  return apiClient.post(END_POINT + requestType, { Bannerad: preparedData }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

/**
 * Duplicate existing Add
 *
 * @param {int} id - add id
 * @returns {Promise} - Request promise
 */
export async function cloneBannerAd(id) {
  // todo add type check
  return apiClient.post(END_POINT + '/duplicate', {
    Bannerad: { Id: id },
  }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}
