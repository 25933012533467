import apiClient from '@/services/axios'

const END_POINT = '/usr/team'

/**
 * Get user's team info
 *
 * @returns {Promise} - Request promise
 */
export async function getTeam() {
  return apiClient.get(END_POINT + '/get').then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

/**
 * Update Team info. All arguments optional
 *
 * @param {Object} payload - Update parameters
 * @returns {Promise} - Request promise
 */
export async function updateTeam(payload) {
  if (typeof payload !== 'object' || payload == null) {
    return false
  }
  // Parameter list
  const parameters = {
    Name: {
      type: 'string',
      required: false,
    },
    Website: {
      type: 'string',
      required: false,
    },
    Country: {
      type: 'string',
      required: false,
    },
    City: {
      type: 'string',
      required: false,
    },
    ZipCode: {
      type: 'string',
      required: false,
    },
    Address: {
      type: 'string',
      required: false,
    },
    Phone: {
      type: 'string',
      required: false,
    },
  }

  const requestData = { Team: apiClient.prepare(payload, parameters) } // TODO: validate locally

  return apiClient.post(END_POINT + '/update', requestData).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}
