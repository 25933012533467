<template>
  <div :class="$style.topbar">
    <div v-if="settings.isMobileView" class="mr-4 mt-2">
      <span @click="toggleMobileMenu"  class="fe fe-menu" style="font-size: 1.6em;"></span>
<!--      <cui-fav-pages />-->
    </div>
    <div class="mr-auto">
<!--      <cui-search />-->
    <router-link :class="$store.state.settings.platform === 0 ? 'btn btn-primary shadow-none rounded py-1' : ''" :to="redirectTo('display-root')"><span class="text-gray" @click="$store.state.settings.platform = 0">DISPLAY</span></router-link>
    <router-link class="ml-5"  :class="$store.state.settings.platform === 1 ? 'btn btn-primary shadow-none rounded py-1 ml-5' : ''" :to="redirectTo('xml-root')"><span class="text-gray" @click="$store.state.settings.platform = 1">SEARCH XML</span></router-link>

    </div>
    <div class="mr-4 d-none d-md-block">
<!--      <cui-issues-history />-->
    </div>
    <div class="mb-0 mr-auto d-xl-block d-none">
<!--      <cui-project-management />-->
    </div>
    <span role="button" class="mr-4 text-secondary">
      <a-dropdown :trigger="['click']" class="" placement="bottomLeft">
        <!-- here -->
        <!-- <div v-if="$store.getters['auth/user'].role === 0" :class="$style.dropdown"> -->
        <div v-if="$store.getters['auth/user'].role === 1" :class="$style.dropdown">
          <i class="fe fe-settings mr-2" :class="$style.icon"></i>
          <span class="d-none d-xl-inline">Admin</span>
        </div>
        <a-menu slot="overlay">
          <a-menu-item-group>
            <a-menu-item class="d-flex justify-content-between">
              <router-link :to="redirectTo('team')" href="javascript: void(0);">Team</router-link><i class="fe fe-users ml-2" />
            </a-menu-item>
            <a-menu-item class="d-flex justify-content-between">
              <router-link :to="redirectTo('filters')" href="javascript: void(0);">Filters</router-link><i class="fe fe-filter ml-2" />
            </a-menu-item>
            <a-menu-item class="d-flex justify-content-between">
              <router-link :to="redirectTo('users')" href="javascript: void(0);">Users</router-link><i class="fe fe-user ml-2" />
            </a-menu-item>
            <a-menu-item class="d-flex justify-content-between">
              <router-link :to="redirectTo('user-create')" href="javascript: void(0);">Create User</router-link><i class="fe fe-user-plus ml-2" />
            </a-menu-item>
          </a-menu-item-group>
          <!-- <a-menu-divider /> -->
          <!-- <a-menu-item>
            <a href="javascript: void(0);">
              <i class="fe fe-users mr-2" />
              Settings
            </a>
          </a-menu-item> -->
        </a-menu>
      </a-dropdown>
    </span>
    <div class="mr-4 d-none d-sm-block">
      <cui-language-switcher />
    </div>
    <div class="mr-4 d-none d-sm-block">
      <cui-actions />
    </div>
    <div>
      <cui-user-menu />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CuiLanguageSwitcher from './LanguageSwitcher'
import CuiActions from './Actions'
import CuiUserMenu from './UserMenu'

export default {
  components: {
    CuiLanguageSwitcher,
    CuiActions,
    CuiUserMenu,
  },
  computed: {
    ...mapState(['settings', 'user']),
  },
  methods: {
    toggleMobileMenu() {
      const value = !this.settings.isMobileMenuOpen
      this.$store.commit('CHANGE_SETTING', { setting: 'isMobileMenuOpen', value })
    },
    redirectTo(route) {
      return this.$router.resolve({ name: route }).resolved.path
    },
  },
  // created() {
  //   console.log(this.$store.getters['auth/user'].role, 'store')
  // },
}
</script>

<style lang="scss" module>
  @import "./style.module.scss";
  .pointer {
    cursor: pointer;
  }
</style>
