import apiClient from '@/services/axios'

const END_POINT = '/xml/pubfeed'

export async function getPubFeedById(id) {
  return apiClient.get(END_POINT + '/get?Id=' + id).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

/**
 * Get publisher feed data
 *
 * @param {int} id - User ID
 * @returns {Promise} - Request promise
 */
export async function getPubFeed(id = null) {
  // const payload = id != null ? { Id: id } : null

  const path = id !== null ? `/get?Id=${id}` : '/get'
  return apiClient.get(END_POINT + path).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

export async function getPubfeedsForPublisher(publisherId) {
  return apiClient.get(END_POINT + '/get', {
    params: { PublisherId: publisherId },
  }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

/**
 * Create new publisher feed
 *
 * @param {Object} payload - Data
 * @returns {Promise} - Request promise
 */
export async function createPubFeed(payload) {
  if (typeof payload !== 'object' || payload == null) {
    return false
  }
  // Parameter list
  const parameters = {
    PublisherId: {
      type: 'int',
      required: true,
      map: 'XMLpublisher',
    },
    Name: {
      type: 'string',
      required: true,
    },
    Description: {
      type: 'string',
      required: false,
    },
    FeedAuth: {
      type: 'string',
      required: false,
    },
    FallbackUrl: {
      type: 'string',
      required: false,
      map: 'fallback',
    },
    ImpressionTrackingMethod: {
      type: 'string',
      required: false,
      map: 'tracking',
    },
    MinCPC: {
      type: 'float',
      required: false,
    },
    MaxCPC: {
      type: 'float',
      required: false,
    },
    SubidListMode: {
      type: 'int',
      required: false,
    },
    SubidList: {
      type: 'arrayOfString',
      required: false,
    },
    AdDomainsBrandsBlacklist: {
      type: 'string',
      required: false,
    },
    RequestsDailyLimit: {
      type: 'int',
      required: false,
    },
    ClicksDailyLimit: {
      type: 'int',
      required: false,
    },
    KeywordBlacklistsType: {
      type: 'int',
      required: false,
    },
    KeywordBlacklists: {
      type: 'arrayOfInt',
      required: 'false',
    },
  }
  // Prepare and validate data
  const preparedData = apiClient.prepare(payload, parameters)
  if (typeof preparedData.type !== 'undefined' && preparedData.type === 'error' && typeof preparedData.message !== 'undefined') {
    return preparedData
  }

  // Format Subid list
  if (typeof preparedData.SubidList !== 'undefined' && preparedData.SubidList.length > 0) {
    preparedData.SubidList = preparedData.SubidList.join('\n')
  }
  // Format AdDomains Brands  Blacklist
  if (typeof preparedData.AdDomainsBrandsBlacklist !== 'undefined' && preparedData.AdDomainsBrandsBlacklist.length > 0) {
    preparedData.AdDomainsBrandsBlacklist = preparedData.AdDomainsBrandsBlacklist.join('\n')
  }

  return apiClient.post(END_POINT + '/add', { Pubfeed: preparedData }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

/**
 * Update publisher feed data
 *
 * @param {Object} payload - Update parameters
 * @returns {Promise} - Request promise
 */
export async function updatePubFeed(payload) {
  if (typeof payload !== 'object' || payload == null) {
    return false
  }
  // Parameter list
  const parameters = {
    Id: {
      type: 'int',
      required: true,
    },
    Name: {
      type: 'string',
      required: false,
    },
    Description: {
      type: 'string',
      required: false,
    },
    FeedAuth: {
      type: 'string',
      required: false,
    },
    FallbackUrl: {
      type: 'string',
      required: false,
      map: 'fallback',
    },
    ImpressionTrackingMethod: {
      type: 'string',
      required: false,
      map: 'tracking',
    },
    MinCPC: {
      type: 'float',
      required: false,
    },
    MaxCPC: {
      type: 'float',
      required: false,
    },
    SubidListMode: {
      type: 'int',
      required: false,
    },
    SubidList: {
      type: 'string',
      required: false,
    },
    AdDomainsBrandsBlacklist: {
      type: 'string',
      required: false,
    },
    RequestsDailyLimit: {
      type: 'int',
      required: false,
    },
    ClicksDailyLimit: {
      type: 'int',
      required: false,
    },
    KeywordBlacklistsType: {
      type: 'int',
      required: false,
    },
    KeywordBlacklists: {
      type: 'arrayOfInt',
      required: 'false',
    },
  }
  // Prepare and validate data
  const preparedData = apiClient.prepare(payload, parameters)
  if (typeof preparedData.type !== 'undefined' && preparedData.type === 'error' && typeof preparedData.message !== 'undefined') {
    return preparedData
  }

  // Format Subid list
  if (typeof preparedData.SubidList !== 'undefined' && preparedData.SubidList.length > 0) {
    preparedData.SubidList = preparedData.SubidList.join('\n')
  }
  // Format AdDomains Brands  Blacklist
  if (typeof preparedData.AdDomainsBrandsBlacklist !== 'undefined' && preparedData.AdDomainsBrandsBlacklist.length > 0) {
    preparedData.AdDomainsBrandsBlacklist = preparedData.AdDomainsBrandsBlacklist.join('\n')
  }

  return apiClient.post(END_POINT + '/update', { Pubfeed: preparedData }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

/**
 * Change publisher feed status
 *
 * @param {Object} payload - Update parameters
 * @returns {Promise} - Request promise
 */
export async function changePubFeedStatus(payload) {
  if (typeof payload !== 'object' || payload == null) {
    return false
  }
  // Parameter list
  const parameters = {
    Id: {
      type: 'int',
      required: true,
    },
    // Status: { type: 'int', required: true },
  }
  // Prepare and validate data
  const preparedData = apiClient.prepare(payload, parameters)
  if (typeof preparedData.type !== 'undefined' && preparedData.type === 'error' && typeof preparedData.message !== 'undefined') {
    return preparedData
  }

  // Get status endpoint
  let requestType = '/activate'
  if (payload.status === 1) {
    requestType = '/deactivate'
  } else if (payload.status === 2) {
    requestType = '/delete'
  }

  return apiClient.post(END_POINT + requestType, { Pubfeed: preparedData }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

/**
 * Duplicate existing Feed
 *
 * @param {int} id - Feed id
 * @returns {Promise} - Request promise
 */
export async function cloneFeed(id) {
  // todo add type check
  return apiClient.post(END_POINT + '/duplicate', {
    Pubfeed: { Id: id },
  }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}
