import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import team from './modules/team'
import user from './modules/user'
import info from './modules/info'
import XMLpublisher from './modules/xml/publisher'
import advertiser from './modules/display/advertiser'
import pubfeed from './modules/xml/pubfeed'
import bannerzone from './modules/display/bannerzone'
import videozone from './modules/display/videozone'
import rtbzone from './modules/display/rtbzone'
import bannerad from './modules/display/bannerad'
import videoad from './modules/display/videoad'
import nativead from './modules/display/nativead'
import remotertbzone from './modules/display/remotertbzone'
import remotevideozone from './modules/display/remotevideozone'
import campaign from './modules/xml/campaign'
import ortbCampaign from './modules/display/campaign'
import ad from './modules/xml/ad'
import creative from './modules/display/creative'
import overlay from './modules/display/overlay'
import companion from './modules/display/companion'
import nativeasset from './modules/display/nativeasset'
import icon from './modules/display/icon'
import ORTBiplist from './modules/display/iplist'
import applist from './modules/display/applist'
import domainlist from './modules/display/domainlist'
import XMLiplist from './modules/xml/iplist'
import kwlist from './modules/xml/kwlist'

import settings from './settings'

import ORTBpublisher from './modules/display/publisher'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    settings,
    auth,
    team,
    user,
    info,
    XMLpublisher,
    ORTBpublisher,
    advertiser,
    pubfeed,
    bannerzone,
    videozone,
    rtbzone,
    remotevideozone,
    bannerad,
    videoad,
    nativead,
    remotertbzone,
    campaign,
    ortbCampaign,
    ad,
    creative,
    overlay,
    companion,
    nativeasset,
    icon,
    XMLiplist,
    ORTBiplist,
    applist,
    domainlist,
    kwlist,
  },
  state: {},
  mutations: {},
  actions: {},
})
