import Vue from 'vue'
import Vuex from 'vuex'
import { getPubFeed, createPubFeed, updatePubFeed, changePubFeedStatus, getPubfeedsForPublisher } from '@/api/xml/pubfeed'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    list: [],
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
    SET_PUBFEED(state, payload) {
      const pubfeed = state.list.find(item => item.Id === parseInt(payload.Id))
      Object.assign(pubfeed, {
        ...payload,
      })
    },
  },
  actions: {
    LOAD_PUBFEEDS_FOR_PUBLISHER({ commit, dispatch, rootState }, payload) {
      getPubfeedsForPublisher(payload.PublisherId).then(response => {
        if (response) {
          commit('SET_STATE', {
            list: response,
          })
        }
      })
    },
    LOAD_PUBFEED({ commit, dispatch, rootState }, id) {
      getPubFeed(id).then(response => {
        if (response) {
          commit('SET_STATE', {
            list: [response],
          })
        }
      })
    },
    LOAD_PUBFEEDS({ commit, dispatch, rootState }) {
      getPubFeed().then(response => {
        if (response) {
          commit('SET_STATE', {
            list: response,
          })
        }
      })
    },
    CREATE_PUBFEED({ commit, dispatch, rootState }, { payload, callback }) {
      createPubFeed(payload).then(response => {
        if (response) {
          if (typeof response.type !== 'undefined' && response.type === 'error') {
            Vue.prototype.$notification.error({
              message: 'Invalid data',
              description: response.message,
            })
          } else if (response.Status === 'Ok') {
            Vue.prototype.$notification.success({
              message: 'Publisher Feed Created',
              description: 'Publisher Feed has been successfully created.',
            })
            callback()
          }
        }
        // TODO: add new publisher feed to store object
        dispatch('LOAD_PUBFEEDS')
      })
    },
    UPDATE_PUBFEED({ commit, dispatch, rootState }, { id, payload, callback }) {
      updatePubFeed({ id, ...payload }).then(response => {
        if (response) {
          commit('SET_PUBFEED', response.Pubfeed)
          Vue.prototype.$notification.success({
            message: 'Publisher Feed Updated',
            description: 'Publisher Feed has been successfully updated.',
          })
          callback()
        }
      })
    },
    CHANGE_STATUS({ commit, dispatch, rootState }, payload) {
      changePubFeedStatus(payload).then(response => {
        if (response) {
          commit('SET_PUBFEED', response.Pubfeed)
          Vue.prototype.$notification.success({
            message: (response.Pubfeed.Status === 2 ? 'Publisher Feed Deleted' : 'Status Changed'),
            description: (response.Pubfeed.Status === 2 ? 'Publisher Feed successfully deleted.' : 'Publisher Feed status successfully changed.'),
          })
        }
      })
    },
  },
  getters: {
    getPubFeedData: (state) => (id) => {
      return state.list.find(item => item.Id === parseInt(id))
    },
    getPubFeeds: (state) => {
      return state.list
    },
    getActivePubFeeds: (state) => {
      return state.list.filter(ele => ele.Status === 0)
    },
  },
}
