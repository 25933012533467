import Vue from 'vue'
import Router from 'vue-router'
import AuthLayout from '@/layouts/Auth'
import MainLayout from '@/layouts/Main'
import store from '@/store'
import { currentAccount } from '@/services/jwt'

Vue.use(Router)

const authorized = store.state.auth.authorized
const router = new Router({
  base: process.env.BASE_URL,
  // mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/xml',
      name: 'xml-root',
      redirect: '/xml/dashboard',
      component: MainLayout,
      meta: {
        authRequired: true,
        hidden: true,
      },
      children: [
        // Team
        // -----------------------------------------
        {
          path: '/team',
          name: 'team',
          meta: {
            title: 'Team',
          },
          component: () => import('./views/team'),
        },
        // Filter
        // -----------------------------------------
        {
          path: '/filters',
          name: 'filters',
          meta: {
            title: 'Filters',
          },
          component: () => import('./views/filter'),
        },
        // Users
        // -----------------------------------------
        {
          path: '/users',
          name: 'users',
          meta: {
            title: 'Users',
          },
          component: () => import('./views/user'),
        },
        {
          path: '/user/create',
          name: 'user-create',
          meta: {
            title: 'Create User',
          },
          component: () => import('./views/user/create'),
        },
        {
          path: '/user/update/:id',
          name: 'user-update',
          meta: {
            title: 'Edit User',
          },
          component: () => import('./views/user/update'),
        },
        {
          path: '/user/account/',
          name: 'user-account',
          meta: {
            title: 'Edit Account',
          },
          component: () => import('./views/user/account'),
        },
        {
          path: '/user/notifications/',
          name: 'user-notifications',
          meta: {
            title: 'Notifications',
          },
          component: () => import('./views/user/notifications'),
        },
        // Publishers
        // -----------------------------------------
        {
          path: '/xml/publishers',
          name: 'xml-publishers',
          meta: {
            title: 'Publishers',
          },
          component: () => import('./views/xml/publisher'),
        },
        {
          path: '/xml/publisher/create',
          name: 'xml-publisher-create',
          meta: {
            title: 'Create Publisher',
          },
          component: () => import('./views/xml/publisher/create'),
        },
        {
          path: '/xml/publisher/update/:id',
          name: 'xml-publisher-update',
          meta: {
            title: 'Edit Publisher',
          },
          component: () => import('./views/xml/publisher/update'),
        },
        {
          path: '/xml/publisher/:id/feeds',
          name: 'xml-publisher-feeds',
          meta: {
            title: 'Publisher Feeds',
          },
          component: () => import('./views/xml/publisher/feeds'),
        },
        // Publishers Feeds
        // -----------------------------------------
        {
          path: '/xml/feeds',
          name: 'xml-feeds',
          meta: {
            title: 'Feeds',
          },
          component: () => import('./views/xml/pubfeed'),
        },
        {
          path: '/xml/feed/create/:publisherId',
          name: 'xml-feed-create-publisher',
          meta: {
            title: 'Create Feed',
          },
          component: () => import('./views/xml/pubfeed/create'),
        },
        {
          path: '/xml/feed/create',
          name: 'xml-feed-create',
          meta: {
            title: 'Create Feed',
          },
          component: () => import('./views/xml/pubfeed/create'),
        },
        {
          path: '/xml/feed/update/:id',
          name: 'xml-feed-update',
          meta: {
            title: 'Edit Feed',
          },
          component: () => import('./views/xml/pubfeed/update'),
        },
        // Campaigns
        // -----------------------------------------
        {
          path: '/xml/campaigns',
          name: 'xml-campaigns',
          meta: {
            title: 'Campaigns',
          },
          component: () => import('./views/xml/campaign'),
        },
        {
          path: '/xml/campaign/create',
          name: 'xml-campaign-create',
          meta: {
            title: 'Create Campaign',
          },
          component: () => import('./views/xml/campaign/create'),
        },
        {
          path: '/xml/campaign/update/:id',
          name: 'xml-campaign-update',
          meta: {
            title: 'Edit Campaign',
          },
          component: () => import('./views/xml/campaign/update'),
        },
        {
          path: '/xml/campaign/:id/ads',
          name: 'xml-campaign-ads',
          meta: {
            title: 'Campaign Ads',
          },
          component: () => import('./views/xml/campaign/ads'),
        },
        // Ads
        // -----------------------------------------
        {
          path: '/xml/ads',
          name: 'xml-ads',
          meta: {
            title: 'Ads',
          },
          component: () => import('./views/xml/ad'),
        },
        {
          path: '/xml/ad/create/:campaignId',
          name: 'xml-ad-create-id',
          meta: {
            title: 'Create Ad',
          },
          component: () => import('./views/xml/ad/create'),
        },
        // {
        //   path: '/xml/ad/create',
        //   name: 'xml-create-ad',
        //   meta: {
        //     title: 'Create Ad',
        //   },
        //   component: () => import('./views/xml/ad/create'),
        // },
        {
          path: '/xml/ad/update/:id',
          name: 'xml-ad-update',
          meta: {
            title: 'Edit Ad',
          },
          component: () => import('./views/xml/ad/update'),
        },
        // Keyword Lists
        // -----------------------------------------
        {
          path: '/xml/kwlist',
          name: 'xml-kwlist',
          meta: {
            title: 'Keyword Lists',
          },
          component: () => import('./views/xml/keywordList/index'),
        },
        {
          path: '/xml/kwlist/update/:id',
          name: 'xml-kwlist-update',
          meta: {
            title: 'Edit Keyword list',
          },
          component: () => import('./views/xml/keywordList/update'),
        },
        // IP Lists
        // -----------------------------------------
        {
          path: '/xml/iplists',
          name: 'xml-iplists',
          meta: {
            title: 'IP Lists',
          },
          component: () => import('./views/xml/iplist'),
        },
        {
          path: '/xml/ad/create',
          name: 'xml-ad-create',
          meta: {
            title: 'Create Ad',
          },
          component: () => import('./views/xml/ad/create'),
        },
        {
          path: '/xml/iplist/update/:id',
          name: 'xml-iplist-update',
          meta: {
            title: 'Edit IP list',
          },
          component: () => import('./views/xml/iplist/update'),
        },
        // Dashboards
        // -----------------------------------------
        {
          path: '/xml/dashboard',
          name: 'xml-dashboard',
          meta: {
            title: 'Dashboard',
          },
          component: () => import('./views/dashboard/xml'),
        },
        {
          path: '/dashboard/beta',
          meta: {
            title: 'Dashboard Beta',
          },
          component: () => import('./views/dashboard/beta'),
        },
        {
          path: '/dashboard/crypto',
          meta: {
            title: 'Dashboard Crypto',
          },
          component: () => import('./views/dashboard/crypto'),
        },
        {
          path: '/dashboard/gamma',
          meta: {
            title: 'Dashboard Gamma',
          },
          component: () => import('./views/dashboard/gamma'),
        },
        // Widgets
        {
          path: '/widgets/general',
          meta: {
            title: 'Widgets / General',
          },
          component: () => import('./views/widgets/general'),
        },
        {
          path: '/widgets/lists',
          meta: {
            title: 'Widgets / Lists',
          },
          component: () => import('./views/widgets/lists'),
        },
        {
          path: '/widgets/tables',
          meta: {
            title: 'Widgets / Tables',
          },
          component: () => import('./views/widgets/tables'),
        },
        {
          path: '/widgets/charts',
          meta: {
            title: 'Widgets / Charts',
          },
          component: () => import('./views/widgets/charts'),
        },
        // Apps
        {
          path: '/apps/calendar',
          meta: {
            title: 'Apps / Calendar',
          },
          component: () => import('./views/apps/calendar'),
        },
        {
          path: '/apps/digitalocean-create',
          meta: {
            title: 'Apps / DigitalOcean Create',
          },
          component: () => import('./views/apps/digitalocean-create'),
        },
        {
          path: '/apps/digitalocean-droplets',
          meta: {
            title: 'Apps / DigitalOcean Droplets',
          },
          component: () => import('./views/apps/digitalocean-droplets'),
        },
        {
          path: '/apps/gallery',
          meta: {
            title: 'Apps / Gallery',
          },
          component: () => import('./views/apps/gallery'),
        },
        {
          path: '/apps/github-discuss',
          meta: {
            title: 'Apps / GitHub Discuss',
          },
          component: () => import('./views/apps/github-discuss'),
        },
        {
          path: '/apps/github-explore',
          meta: {
            title: 'Apps / GitHub Explore',
          },
          component: () => import('./views/apps/github-explore'),
        },
        {
          path: '/apps/google-analytics',
          meta: {
            title: 'Apps / Google Analytics',
          },
          component: () => import('./views/apps/google-analytics'),
        },
        {
          path: '/apps/helpdesk-dashboard',
          meta: {
            title: 'Apps / Helpdesk Dashboard',
          },
          component: () => import('./views/apps/helpdesk-dashboard'),
        },
        {
          path: '/apps/jira-agile-board',
          meta: {
            title: 'Apps / Jira Agile Board',
          },
          component: () => import('./views/apps/jira-agile-board'),
        },
        {
          path: '/apps/jira-dashboard',
          meta: {
            title: 'Apps / Jira Dashboard',
          },
          component: () => import('./views/apps/jira-dashboard'),
        },
        {
          path: '/apps/mail',
          meta: {
            title: 'Apps / Mail',
          },
          component: () => import('./views/apps/mail'),
        },
        {
          path: '/apps/messaging',
          meta: {
            title: 'Apps / Mesagging',
          },
          component: () => import('./views/apps/messaging'),
        },
        {
          path: '/apps/profile',
          meta: {
            title: 'Apps / Profile',
          },
          component: () => import('./views/apps/profile'),
        },
        {
          path: '/apps/todoist-list',
          meta: {
            title: 'Apps / Todoist List',
          },
          component: () => import('./views/apps/todoist-list'),
        },
        {
          path: '/apps/wordpress-add',
          meta: {
            title: 'Apps / Wordpress Add',
          },
          component: () => import('./views/apps/wordpress-add'),
        },
        {
          path: '/apps/wordpress-post',
          meta: {
            title: 'Apps / Wordpress Post',
          },
          component: () => import('./views/apps/wordpress-post'),
        },
        {
          path: '/apps/wordpress-posts',
          meta: {
            title: 'Apps / Wordpress Posts',
          },
          component: () => import('./views/apps/wordpress-posts'),
        },
        // Cards
        {
          path: '/cards/basic-cards',
          meta: {
            title: 'Cards / Basic',
          },
          component: () => import('./views/cards/basic-cards'),
        },
        {
          path: '/cards/tabbed-cards',
          meta: {
            title: 'Cards / Tabbed',
          },
          component: () => import('./views/cards/tabbed-cards'),
        },
        // UI Kits
        {
          path: '/ui-kits/bootstrap',
          meta: {
            title: 'UI Kit / Bootstrap',
          },
          component: () => import('./views/ui-kits/bootstrap'),
        },
        {
          path: '/ui-kits/antd',
          meta: {
            title: 'UI Kit / Ant Design',
          },
          component: () => import('./views/ui-kits/antd'),
        },
        // Tables
        {
          path: '/tables/bootstrap',
          meta: {
            title: 'Tables / Bootstrap',
          },
          component: () => import('./views/tables/bootstrap'),
        },
        {
          path: '/tables/antd',
          meta: {
            title: 'Tables / Ant Design',
          },
          component: () => import('./views/tables/antd'),
        },
        // Charts
        {
          path: '/charts/chartistjs',
          meta: {
            title: 'Charts / Chartist.js',
          },
          component: () => import('./views/charts/chartistjs'),
        },
        {
          path: '/charts/chartjs',
          meta: {
            title: 'Charts / Chart.js',
          },
          component: () => import('./views/charts/chartjs'),
        },
        {
          path: '/charts/c3',
          meta: {
            title: 'Charts / C3 Charts',
          },
          component: () => import('./views/charts/c3'),
        },
        // Icons
        {
          path: '/icons/feather-icons',
          meta: {
            title: 'Icons / Feather',
          },
          component: () => import('./views/icons/feather-icons'),
        },
        {
          path: '/icons/fontawesome',
          meta: {
            title: 'Icons / Fontawesome',
          },
          component: () => import('./views/icons/fontawesome'),
        },
        {
          path: '/icons/linearicons-free',
          meta: {
            title: 'Icons / Linearicons Free',
          },
          component: () => import('./views/icons/linearicons-free'),
        },
        {
          path: '/icons/icomoon-free',
          meta: {
            title: 'Icons / Icomoon Free',
          },
          component: () => import('./views/icons/icomoon-free'),
        },
        // Advanced
        {
          path: '/advanced/form-examples',
          meta: {
            title: 'Advanced / Form Examples',
          },
          component: () => import('./views/advanced/form-examples'),
        },
        {
          path: '/advanced/email-templates',
          meta: {
            title: 'Advanced / Email Templates',
          },
          component: () => import('./views/advanced/email-templates'),
        },
        {
          path: '/advanced/utilities',
          meta: {
            title: 'Advanced / Utilities',
          },
          component: () => import('./views/advanced/utilities'),
        },
        {
          path: '/advanced/grid',
          meta: {
            title: 'Advanced / Grid',
          },
          component: () => import('./views/advanced/grid'),
        },
        {
          path: '/advanced/typography',
          meta: {
            title: 'Advanced / Typography',
          },
          component: () => import('./views/advanced/typography'),
        },
        {
          path: '/advanced/pricing-tables',
          meta: {
            title: 'Advanced / Pricing Tables',
          },
          component: () => import('./views/advanced/pricing-tables'),
        },
        {
          path: '/advanced/invoice',
          meta: {
            title: 'Advanced / Invoice',
          },
          component: () => import('./views/advanced/invoice'),
        },
        {
          path: '/advanced/colors',
          meta: {
            title: 'Advanced / Colors',
          },
          component: () => import('./views/advanced/colors'),
        },
        {
          path: '/xml/reports/pubfeed',
          name: 'xml-reports-pubfeed',
          meta: {
            title: 'Reports By Feed',
          },
          component: () => import('./views/xml/reports/pubfeed'),
        },
        {
          path: '/xml/reports/ad',
          name: 'xml-reports-ad',
          meta: {
            title: 'Reports By Ad',
          },
          component: () => import('./views/xml/reports/ad'),
        },
        {
          path: '/xml/reports/campaign',
          name: 'xml-reports-campaign',
          meta: {
            title: 'Reports By Campaign',
          },
          component: () => import('./views/xml/reports/campaign'),
        },
        {
          path: '/xml/reports/keywords',
          name: 'xml-reports-keywords',
          meta: {
            title: 'Reports for Keywords',
          },
          component: () => import('./views/xml/reports/keywords'),
        },
        {
          path: '/xml/reports/subid',
          name: 'xml-reports-subid',
          meta: {
            title: 'Reports for Sub ID',
          },
          component: () => import('./views/xml/reports/subid'),
        },
      ],
    },
    // DISPLAY MODULE BELOW
    {
      path: '/display',
      name: 'display-root',
      redirect: '/display/dashboard',
      component: MainLayout,
      meta: {
        authRequired: true,
        hidden: true,
      },
      children: [
        {
          path: '/user/update/:id',
          meta: {
            title: 'Edit User',
          },
          component: () => import('./views/user/update'),
        },
        {
          path: '/user/account/',
          meta: {
            title: 'Edit Account',
          },
          component: () => import('./views/user/account'),
        },
        // Publishers
        // -----------------------------------------
        {
          path: '/display/publishers',
          name: 'display-publishers',
          meta: {
            title: 'Publishers',
          },
          component: () => import('./views/display/publisher'),
        },
        {
          path: '/display/publisher/create',
          name: 'display-publisher-create',
          meta: {
            title: 'Create Publisher',
          },
          component: () => import('./views/display/publisher/create'),
        },
        {
          path: '/display/publisher/update/:id',
          name: 'display-publisher-update',
          meta: {
            title: 'Edit Publisher',
          },
          component: () => import('./views/display/publisher/update'),
        },
        {
          path: '/display/publisher/:id/bannerzones',
          name: 'display-publisher-bannerzones',
          meta: {
            title: 'Publisher Banner Zones',
          },
          component: () => import('./views/display/publisher/bannerzones'),
        },
        {
          path: '/display/publisher/:id/videozones',
          name: 'display-publisher-videozones',
          meta: {
            title: 'Publisher Video Zones',
          },
          component: () => import('./views/display/publisher/videozones'),
        },
        {
          path: '/display/publisher/:id/rtbzones',
          name: 'display-publisher-rtbzones',
          meta: {
            title: 'Publisher RTB Zones',
          },
          component: () => import('./views/display/publisher/rtbzones'),
        },
        // Publishers Feeds
        // -----------------------------------------
        {
          path: '/display/bannerzones',
          name: 'display-bannerzones',
          meta: {
            title: 'Banner Zones',
          },
          component: () => import('./views/display/bannerzone'),
        },
        {
          path: '/display/bannerzone/create/:publisherId',
          name: 'create-bannerzone-pubId',
          meta: {
            title: 'Create Banner Zone',
          },
          component: () => import('./views/display/bannerzone/create'),
        },
        {
          path: '/display/rtbzone/create/:publisherId',
          name: 'create-rtbzone-pubId',
          meta: {
            title: 'Create RTB Zone',
          },
          component: () => import('./views/display/rtbzone/create'),
        },
        {
          path: '/display/bannerzone/create',
          name: 'display-bannerzone-create',
          meta: {
            title: 'Create Banner Zone',
          },
          component: () => import('./views/display/bannerzone/create'),
        },
        {
          path: '/display/bannerzone/update/:id',
          name: 'display-bannerzone-update',
          meta: {
            title: 'Edit Banner Zone',
          },
          component: () => import('./views/display/bannerzone/update'),
        },
        // Video Zones
        // -----------------------------------------
        {
          path: '/display/videozones',
          name: 'display-videozones',
          meta: {
            title: 'Video Zones',
          },
          component: () => import('./views/display/videozone'),
        },
        {
          path: '/display/videozone/create',
          name: 'display-videozone-create',
          meta: {
            title: 'Create Video Zone',
          },
          component: () => import('./views/display/videozone/create'),
        },
        {
          path: '/display/videozone/create/:publisherId',
          name: 'create-videozone-pubId',
          meta: {
            title: 'Create Banner',
          },
          component: () => import('./views/display/videozone/create'),
        },
        {
          path: '/display/videozone/update/:id',
          name: 'display-videozone-update',
          meta: {
            title: 'Edit Video Zone',
          },
          component: () => import('./views/display/videozone/update'),
        },
        // RTB Zones
        {
          path: '/display/rtbzones',
          name: 'display-rtbzones',
          meta: {
            title: 'RTB Zones',
          },
          component: () => import('./views/display/rtbzone'),
        },
        {
          path: '/display/rtbzone/create',
          name: 'display-rtbzone-create',
          meta: {
            title: 'Create RTB Zone',
          },
          component: () => import('./views/display/rtbzone/create'),
        },
        {
          path: '/display/rtbzone/update/:id',
          name: 'display-rtbzone-update',
          meta: {
            title: 'Edit RTB Zone',
          },
          component: () => import('./views/display/rtbzone/update'),
        },
        // Advertisers+
        // -----------------------------------------
        {
          path: '/display/advertisers',
          name: 'display-advertisers',
          meta: {
            title: 'Advertisers',
          },
          component: () => import('./views/display/advertiser'),
        },
        {
          path: '/display/advertiser/create',
          name: 'display-advertiser-create',
          meta: {
            title: 'Create Advertiser',
          },
          component: () => import('./views/display/advertiser/create'),
        },
        {
          path: '/display/advertiser/update/:id',
          name: 'display-advertiser-update',
          meta: {
            title: 'Edit Advertiser',
          },
          component: () => import('./views/display/advertiser/update'),
        },
        // Image Library
        {
          path: '/display/library/images',
          name: 'display-library-images',
          meta: {
            title: 'Image Library',
          },
          component: () => import('./views/display/creative/imagelibrary'),
        },
        // Video Library
        {
          path: '/display/library/videos',
          name: 'display-library-videos',
          meta: {
            title: 'Video Library',
          },
          component: () => import('./views/display/creative/videolibrary'),
        },
        // Sites
        {
          path: '/display/library/sites',
          name: 'display-library-sites',
          meta: {
            title: 'Sites Library',
          },
          component: () => import('./views/display/Sites/'),
        },
        // Apps
        {
          path: '/display/library/apps',
          name: 'display-library-apps',
          meta: {
            title: 'Apps Library',
          },
          component: () => import('./views/display/Apps/'),
        },
        // CRIDs
        {
          path: '/display/library/crids',
          name: 'display-library-crids',
          meta: {
            title: 'CRIDs Library',
          },
          component: () => import('./views/display/Crids/index.vue'),
        },
        // Edit Creative
        {
          path: '/display/creative/update/:id',
          name: 'display-creative-update',
          meta: {
            title: 'Edit Creative',
          },
          component: () => import('./views/display/creative/update.vue'),
        },
        // Campaigns
        // -----------------------------------------
        {
          path: '/display/campaigns',
          name: 'display-campaigns',
          meta: {
            title: 'Campaigns',
          },
          component: () => import('./views/display/campaign'),
        },
        {
          path: '/display/campaign/create',
          name: 'display-campaign-create',
          meta: {
            title: 'Create Campaign',
          },
          component: () => import('./views/display/campaign/create'),
        },
        {
          path: '/display/campaign/update/:id',
          name: 'display-campaign-update',
          meta: {
            title: 'Edit Campaign',
          },
          component: () => import('./views/display/campaign/update'),
        },
        // {
        //   path: '/display/campaign/:id/ads',
        //   name: '',
        //   meta: {
        //     title: 'Campaign ads',
        //   },
        //   component: () => import('./views/display/campaign/ads'),
        // },
        {
          path: '/display/campaign/:id/bannerads',
          name: 'display-campaign-bannerads',
          meta: {
            title: 'Campaign Banner Ads',
          },
          component: () => import('./views/display/campaign/bannerads'),
        },
        // Banner Ads
        // -----------------------------------------
        {
          path: '/display/bannerads',
          name: 'display-bannerads',
          meta: {
            title: 'Banner Ads',
          },
          component: () => import('./views/display/bannerad'),
        },
        {
          path: '/display/bannerad/create/:campaignId',
          name: 'create-bannerad-campaignId',
          meta: {
            title: 'Create Banner Ad',
          },
          component: () => import('./views/display/bannerad/create'),
        },
        {
          path: '/display/bannerad/create',
          name: 'display-bannerad-create',
          meta: {
            title: 'Create Banner Ad',
          },
          component: () => import('./views/display/bannerad/create'),
        },
        {
          path: '/display/bannerad/update/:id',
          name: 'display-bannerad-update',
          meta: {
            title: 'Edit Banner Ad',
          },
          component: () => import('./views/display/bannerad/update'),
        },
        // Video Ads
        // -----------------------------------------
        {
          path: '/display/videoads',
          name: 'display-videoads',
          meta: {
            title: 'Video Ads',
          },
          component: () => import('./views/display/videoad'),
        },
        {
          path: '/display/videoad/create/:campaignId',
          name: 'create-videoad-campaignId',
          meta: {
            title: 'Create Video Ad',
          },
          component: () => import('./views/display/videoad/create'),
        },
        {
          path: '/display/videoad/create',
          name: 'display-videoad-create',
          meta: {
            title: 'Create Video Ad',
          },
          component: () => import('./views/display/videoad/create'),
        },
        {
          path: '/display/videoad/update/:id',
          name: 'display-videoad-update',
          meta: {
            title: 'Edit Video Ad',
          },
          component: () => import('./views/display/videoad/update'),
        },
        {
          path: '/display/campaign/:id/videoads',
          name: 'display-campaign-videoads',
          meta: {
            title: 'Campaign Video Ads',
          },
          component: () => import('./views/display/campaign/videoads'),
        },
        // Native Ads
        // -----------------------------------------
        {
          path: '/display/nativeads',
          name: 'display-nativeads',
          meta: {
            title: 'Native Ads',
          },
          component: () => import('./views/display/nativead'),
        },
        {
          path: '/display/nativead/create/:campaignId',
          name: 'create-nativead-campaignId',
          meta: {
            title: 'Create Native Ad',
          },
          component: () => import('./views/display/nativead/create'),
        },
        {
          path: '/display/nativead/create',
          name: 'display-nativead-create',
          meta: {
            title: 'Create Native Ad',
          },
          component: () => import('./views/display/nativead/create'),
        },
        {
          path: '/display/nativead/update/:id',
          name: 'display-nativead-update',
          meta: {
            title: 'Edit Native Ad',
          },
          component: () => import('./views/display/nativead/update'),
        },
        {
          path: '/display/campaign/:id/nativeads',
          name: 'display-campaign-nativeads',
          meta: {
            title: 'Campaign Native Ads',
          },
          component: () => import('./views/display/campaign/nativeads'),
        },
        // Remote RTB Zones
        // -----------------------------------------
        {
          path: '/display/remotertbzones',
          name: 'display-remotertbzones',
          meta: {
            title: 'Remote RTB Zones',
          },
          component: () => import('./views/display/remotertbzone'),
        },
        {
          path: '/display/remotertbzone/create/:advertiserId',
          name: 'create-remotertbzone-advertiserId',
          meta: {
            title: 'Create Remote RTB Zone',
          },
          component: () => import('./views/display/remotertbzone/create'),
        },
        {
          path: '/display/remotertbzone/create',
          name: 'display-remotertbzone-create',
          meta: {
            title: 'Create Remote RTB Zone',
          },
          component: () => import('./views/display/remotertbzone/create'),
        },
        {
          path: '/display/remotertbzone/update/:id',
          name: 'display-remotertbzone-update',
          meta: {
            title: 'Edit Remote RTB Zone',
          },
          component: () => import('./views/display/remotertbzone/update'),
        },
        {
          path: '/display/advertiser/:id/remotertbzones',
          name: 'display-advertiser-remotertbzones',
          meta: {
            title: 'Advertiser Remote RTB Zones',
          },
          component: () => import('./views/display/advertiser/remotertbzones'),
        },
        // Remote Video Zones
        // -----------------------------------------
        {
          path: '/display/remotevideozones',
          name: 'display-remotevideozones',
          meta: {
            title: 'Remote Video Zones',
          },
          component: () => import('./views/display/remotevideozone'),
        },
        {
          path: '/display/remotevideozone/create/:advertiserId',
          name: 'create-remotevideozone-advertiserId',
          meta: {
            title: 'Create Remote Video Zone',
          },
          component: () => import('./views/display/remotevideozone/create'),
        },
        {
          path: '/display/remotevideozone/create',
          name: 'display-remotevideozone-create',
          meta: {
            title: 'Create Remote Video Zone',
          },
          component: () => import('./views/display/remotevideozone/create'),
        },
        {
          path: '/display/advertiser/:id/campaigns',
          name: 'display-advertiser-campaigns',
          meta: {
            title: 'Advertiser Campaigns',
          },
          component: () => import('./views/display/advertiser/campaigns'),
        },
        {
          path: '/display/campaign/create/:advertiserId',
          name: 'create-campaign-advertiserId',
          meta: {
            title: 'Create Campaign',
          },
          component: () => import('./views/display/campaign/create'),
        },
        {
          path: '/display/advertiser/:id/remotevideozones',
          name: 'display-advertiser-remotevideozones',
          meta: {
            title: 'Advertiser Remote Video Zones',
          },
          component: () => import('./views/display/advertiser/remotevideozones'),
        },
        {
          path: '/display/remotevideozone/update/:id',
          name: 'display-remotevideozone-update',
          meta: {
            title: 'Edit Remote Video Zone',
          },
          component: () => import('./views/display/remotevideozone/update'),
        },
        // {
        //   path: '/display/campaign/:id/remotevideozones',
        //   name: 'display-campaign-remotevideozones',
        //   meta: {
        //     title: 'Campaign Remote Video Zones',
        //   },
        //   component: () => import('./views/display/campaign/remotevideozones'),
        // },
        // IP Lists
        // -----------------------------------------
        {
          path: '/display/iplists',
          name: 'display-iplists',
          meta: {
            title: 'IP Lists',
          },
          component: () => import('./views/display/iplist'),
        },
        {
          path: '/display/iplist/update/:id',
          name: 'display-iplist-update',
          meta: {
            title: 'Edit IP list',
          },
          component: () => import('./views/display/iplist/update'),
        },
        // App Lists
        // -----------------------------------------
        {
          path: '/display/applists',
          name: 'display-applists',
          meta: {
            title: 'App Lists',
          },
          component: () => import('./views/display/applist'),
        },
        {
          path: '/display/applist/update/:id',
          name: 'display-applist-update',
          meta: {
            title: 'Edit App list',
          },
          component: () => import('./views/display/applist/update'),
        },
        // Domain Lists
        // -----------------------------------------
        {
          path: '/display/domainlists',
          name: 'display-domainlists',
          meta: {
            title: 'Domain Lists',
          },
          component: () => import('./views/display/domainlist'),
        },
        {
          path: '/display/domainlist/update/:id',
          name: 'display-domainlist-update',
          meta: {
            title: 'Edit Domain list',
          },
          component: () => import('./views/display/domainlist/update'),
        },
        // Dashboards
        // -----------------------------------------
        {
          path: '/display/dashboard',
          name: 'display-dashboard',
          meta: {
            title: 'Dashboard',
          },
          component: () => import('./views/dashboard/display'),
        },
        {
          path: '/dashboard/beta',
          meta: {
            title: 'Dashboard Beta',
          },
          component: () => import('./views/dashboard/beta'),
        },
        {
          path: '/dashboard/crypto',
          meta: {
            title: 'Dashboard Crypto',
          },
          component: () => import('./views/dashboard/crypto'),
        },
        {
          path: '/dashboard/gamma',
          meta: {
            title: 'Dashboard Gamma',
          },
          component: () => import('./views/dashboard/gamma'),
        },
        // Widgets
        {
          path: '/widgets/general',
          meta: {
            title: 'Widgets / General',
          },
          component: () => import('./views/widgets/general'),
        },
        {
          path: '/widgets/lists',
          meta: {
            title: 'Widgets / Lists',
          },
          component: () => import('./views/widgets/lists'),
        },
        {
          path: '/widgets/tables',
          meta: {
            title: 'Widgets / Tables',
          },
          component: () => import('./views/widgets/tables'),
        },
        {
          path: '/widgets/charts',
          meta: {
            title: 'Widgets / Charts',
          },
          component: () => import('./views/widgets/charts'),
        },
        // Apps
        {
          path: '/apps/calendar',
          meta: {
            title: 'Apps / Calendar',
          },
          component: () => import('./views/apps/calendar'),
        },
        {
          path: '/apps/digitalocean-create',
          meta: {
            title: 'Apps / DigitalOcean Create',
          },
          component: () => import('./views/apps/digitalocean-create'),
        },
        {
          path: '/apps/digitalocean-droplets',
          meta: {
            title: 'Apps / DigitalOcean Droplets',
          },
          component: () => import('./views/apps/digitalocean-droplets'),
        },
        {
          path: '/apps/gallery',
          meta: {
            title: 'Apps / Gallery',
          },
          component: () => import('./views/apps/gallery'),
        },
        {
          path: '/apps/github-discuss',
          meta: {
            title: 'Apps / GitHub Discuss',
          },
          component: () => import('./views/apps/github-discuss'),
        },
        {
          path: '/apps/github-explore',
          meta: {
            title: 'Apps / GitHub Explore',
          },
          component: () => import('./views/apps/github-explore'),
        },
        {
          path: '/apps/google-analytics',
          meta: {
            title: 'Apps / Google Analytics',
          },
          component: () => import('./views/apps/google-analytics'),
        },
        {
          path: '/apps/helpdesk-dashboard',
          meta: {
            title: 'Apps / Helpdesk Dashboard',
          },
          component: () => import('./views/apps/helpdesk-dashboard'),
        },
        {
          path: '/apps/jira-agile-board',
          meta: {
            title: 'Apps / Jira Agile Board',
          },
          component: () => import('./views/apps/jira-agile-board'),
        },
        {
          path: '/apps/jira-dashboard',
          meta: {
            title: 'Apps / Jira Dashboard',
          },
          component: () => import('./views/apps/jira-dashboard'),
        },
        {
          path: '/apps/mail',
          meta: {
            title: 'Apps / Mail',
          },
          component: () => import('./views/apps/mail'),
        },
        {
          path: '/apps/messaging',
          meta: {
            title: 'Apps / Mesagging',
          },
          component: () => import('./views/apps/messaging'),
        },
        {
          path: '/apps/profile',
          meta: {
            title: 'Apps / Profile',
          },
          component: () => import('./views/apps/profile'),
        },
        {
          path: '/apps/todoist-list',
          meta: {
            title: 'Apps / Todoist List',
          },
          component: () => import('./views/apps/todoist-list'),
        },
        {
          path: '/apps/wordpress-add',
          meta: {
            title: 'Apps / Wordpress Add',
          },
          component: () => import('./views/apps/wordpress-add'),
        },
        {
          path: '/apps/wordpress-post',
          meta: {
            title: 'Apps / Wordpress Post',
          },
          component: () => import('./views/apps/wordpress-post'),
        },
        {
          path: '/apps/wordpress-posts',
          meta: {
            title: 'Apps / Wordpress Posts',
          },
          component: () => import('./views/apps/wordpress-posts'),
        },
        // Cards
        {
          path: '/cards/basic-cards',
          meta: {
            title: 'Cards / Basic',
          },
          component: () => import('./views/cards/basic-cards'),
        },
        {
          path: '/cards/tabbed-cards',
          meta: {
            title: 'Cards / Tabbed',
          },
          component: () => import('./views/cards/tabbed-cards'),
        },
        // UI Kits
        {
          path: '/ui-kits/bootstrap',
          meta: {
            title: 'UI Kit / Bootstrap',
          },
          component: () => import('./views/ui-kits/bootstrap'),
        },
        {
          path: '/ui-kits/antd',
          meta: {
            title: 'UI Kit / Ant Design',
          },
          component: () => import('./views/ui-kits/antd'),
        },
        // Tables
        {
          path: '/tables/bootstrap',
          meta: {
            title: 'Tables / Bootstrap',
          },
          component: () => import('./views/tables/bootstrap'),
        },
        {
          path: '/tables/antd',
          meta: {
            title: 'Tables / Ant Design',
          },
          component: () => import('./views/tables/antd'),
        },
        // Charts
        {
          path: '/charts/chartistjs',
          meta: {
            title: 'Charts / Chartist.js',
          },
          component: () => import('./views/charts/chartistjs'),
        },
        {
          path: '/charts/chartjs',
          meta: {
            title: 'Charts / Chart.js',
          },
          component: () => import('./views/charts/chartjs'),
        },
        {
          path: '/charts/c3',
          meta: {
            title: 'Charts / C3 Charts',
          },
          component: () => import('./views/charts/c3'),
        },
        // Icons
        {
          path: '/icons/feather-icons',
          meta: {
            title: 'Icons / Feather',
          },
          component: () => import('./views/icons/feather-icons'),
        },
        {
          path: '/icons/fontawesome',
          meta: {
            title: 'Icons / Fontawesome',
          },
          component: () => import('./views/icons/fontawesome'),
        },
        {
          path: '/icons/linearicons-free',
          meta: {
            title: 'Icons / Linearicons Free',
          },
          component: () => import('./views/icons/linearicons-free'),
        },
        {
          path: '/icons/icomoon-free',
          meta: {
            title: 'Icons / Icomoon Free',
          },
          component: () => import('./views/icons/icomoon-free'),
        },
        // Advanced
        {
          path: '/advanced/form-examples',
          meta: {
            title: 'Advanced / Form Examples',
          },
          component: () => import('./views/advanced/form-examples'),
        },
        {
          path: '/advanced/email-templates',
          meta: {
            title: 'Advanced / Email Templates',
          },
          component: () => import('./views/advanced/email-templates'),
        },
        {
          path: '/advanced/utilities',
          meta: {
            title: 'Advanced / Utilities',
          },
          component: () => import('./views/advanced/utilities'),
        },
        {
          path: '/advanced/grid',
          meta: {
            title: 'Advanced / Grid',
          },
          component: () => import('./views/advanced/grid'),
        },
        {
          path: '/advanced/typography',
          meta: {
            title: 'Advanced / Typography',
          },
          component: () => import('./views/advanced/typography'),
        },
        {
          path: '/advanced/pricing-tables',
          meta: {
            title: 'Advanced / Pricing Tables',
          },
          component: () => import('./views/advanced/pricing-tables'),
        },
        {
          path: '/advanced/invoice',
          meta: {
            title: 'Advanced / Invoice',
          },
          component: () => import('./views/advanced/invoice'),
        },
        {
          path: '/advanced/colors',
          meta: {
            title: 'Advanced / Colors',
          },
          component: () => import('./views/advanced/colors'),
        },
        {
          path: '/display/reports/zone',
          name: 'display-reports-zone',
          meta: {
            title: 'Reports By Zone',
          },
          component: () => import('./views/display/reports/zone'),
        },
        {
          path: '/display/reports/ad',
          name: 'display-reports-ad',
          meta: {
            title: 'Reports By Ad',
          },
          component: () => import('./views/display/reports/ad'),
        },
        // {
        //   path: '/display/reports/campaign',
        //   name: 'display-reports-campaign',
        //   meta: {
        //     title: 'Reports By Campaign',
        //   },
        //   component: () => import('./views/display/reports/campaign'),
        // },
        {
          path: '/display/reports/seatscrids',
          name: 'display-reports-seatscrids',
          meta: {
            title: 'Seats/CRIDs',
          },
          component: () => import('./views/display/reports/seatsCrids'),
        },
        {
          path: '/display/reports/video',
          name: 'display-reports-video',
          meta: {
            title: 'Reports for Video',
          },
          component: () => import('./views/display/reports/video'),
        },
        {
          path: '/display/reports/error',
          name: 'display-reports-error',
          meta: {
            title: 'Reports for Errors',
          },
          component: () => import('./views/display/reports/error'),
        },
        {
          path: '/display/reports/schain',
          name: 'display-reports-schain',
          meta: {
            title: 'Reports for SChain Errors',
          },
          component: () => import('./views/display/reports/schain'),
        },
        {
          path: '/display/reports/ivt',
          name: 'display-reports-ivt',
          meta: {
            title: 'Reports for IVT',
          },
          component: () => import('./views/display/reports/ivt'),
        },
        {
          path: '/display/reports/geoedge',
          name: 'display-reports-geoedge',
          meta: {
            title: 'Geoedge Alerts',
          },
          component: () => import('./views/display/reports/geoedge'),
        },
        {
          path: '/display/reports/apps/zone',
          name: 'display-reports-apps-zone',
          meta: {
            title: 'Apps By Zone',
          },
          component: () => import('./views/display/appReports/zone'),
        },
        {
          path: '/display/reports/apps/ad',
          name: 'display-reports-apps-ad',
          meta: {
            title: 'Apps By Ad',
          },
          component: () => import('./views/display/appReports/ad'),
        },
        {
          path: '/display/reports/sites/zone',
          name: 'display-reports-sites-zone',
          meta: {
            title: 'Sites By Zone',
          },
          component: () => import('./views/display/siteReports/zone'),
        },
        {
          path: '/display/reports/sites/ad',
          name: 'display-reports-sites-ad',
          meta: {
            title: 'Sites By Ad',
          },
          component: () => import('./views/display/siteReports/ad'),
        },
        {
          path: '/display/reports/sizes/zone',
          name: 'display-reports-sizes-zone',
          meta: {
            title: 'Sizes By Zone',
          },
          component: () => import('./views/display/sizeReports/zone'),
        },
        {
          path: '/display/reports/sizes/ad',
          name: 'display-reports-sizes-ad',
          meta: {
            title: 'Sizes By Ad',
          },
          component: () => import('./views/display/sizeReports/ad'),
        },
        {
          path: '/display/reports/pub/zone',
          name: 'display-reports-pub-zone',
          meta: {
            title: 'Pub IDs By Zone',
          },
          component: () => import('./views/display/pubReports/zone'),
        },
        {
          path: '/display/reports/pub/ad',
          name: 'display-reports-pub-ad',
          meta: {
            title: 'Pub IDs By Ad',
          },
          component: () => import('./views/display/pubReports/ad'),
        },
      ],
    },

    // System Pages
    {
      path: '/auth',
      component: AuthLayout,
      redirect: 'auth/login',
      children: [
        {
          path: '/registration-pending',
          meta: {
            title: 'Registration Pending',
          },
          component: () => import('./views/landing/register-pending'),
        },
        {
          path: '/auth/404',
          meta: {
            title: 'Error 404',
          },
          component: () => import('./views/auth/404'),
        },
        {
          path: '/auth/500',
          meta: {
            title: 'Error 500',
          },
          component: () => import('./views/auth/500'),
        },
        {
          path: '/auth/login',
          name: 'auth-login',
          meta: {
            title: 'Sign In',
            conditionalRoute: !authorized,
          },
          component: () => import('./views/auth/login'),
          // beforeEnter: (to, from, next) => {
          //   if (store.getters['auth/user'].authorized) {
          //     console.log('authorized')
          //     // next(false)
          //     // from(false)
          //   } else {
          //     console.log('NOT authorized')
          //   }
          //   // console.log(store.getters['auth/user'].authorized, 'store.auth')
          // },
        },
        {
          path: '/auth/register',
          meta: {
            title: 'Sign Up',
          },
          component: () => import('./views/auth/register'),
        },
        {
          path: '/auth/forgot-password',
          meta: {
            title: 'Forgot Password',
          },
          component: () => import('./views/auth/forgot-password'),
        },
        {
          path: '/auth/lockscreen',
          meta: {
            title: 'Lockscreen',
          },
          component: () => import('./views/auth/lockscreen'),
        },
      ],
    },
    // Redirect to 404
    {
      path: '*', redirect: 'auth/404', hidden: true,
    },
    {
      path: '/',
      name: 'root',
      redirect: authorized ? '/auth/login' : false,
      beforeEnter: (to, from, next) => {
        authorized ? to({ name: 'auth-login' }) : next({ name: 'xml-dashboard' })
      },
    },
  ],
})

router.beforeEach((to, from, next) => {
  if (to.path.indexOf('/xml') === 0) {
    store.state.settings.platform = 1
  } else if (to.path.indexOf('/display') === 0) {
    store.state.settings.platform = 0
  }
  if (to.matched.some(record => record.meta.authRequired)) {
    if (!store.state.auth.authorized) {
      store.dispatch('auth/LOAD_CURRENT_ACCOUNT')
      currentAccount().then(response => {
        if (response && to.path !== '/#/auth/login') {
          store.dispatch('auth/LOAD_CURRENT_ACCOUNT')
          next()
        } else if (next.conditionalRoute) {
          next({
            path: '/xml/dashboard',
            query: { redirect: to.fullPath },
          })
        } else {
          next({
            path: '/auth/login',
            query: { redirect: to.fullPath },
          })
        }
      }).catch(err => console.log(err))
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
